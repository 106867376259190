<template>
  <div>
    <el-row>
      <el-col>
        <el-form :inline='true'>
	          
	          <el-form-item  width="120">
		          <el-input v-model="filters.keyWord" placeholder="学员名称或手机号"></el-input>
	          </el-form-item>
	          <el-form-item>
		          <el-date-picker
				          v-model="filters.time"
				          type="daterange"
				          format="yyyy-MM-dd"
				          value-format="yyyy-MM-dd"
				          range-separator="至"
				          start-placeholder="开始日期"
				          end-placeholder="结束日期">
		          </el-date-picker>
          </el-form-item>
	        <el-form-item label="">
		        <el-cascader
				        v-model="filters.skillType"
				        :options="organizeNums"
				        @change="handleChange"
				        :props="defaultProps"
				        lazy
				        :show-all-levels="false"
				        clearable
				        style="width: 80%"
				        placeholder="组织管理"
		        >
		        </el-cascader>
	        </el-form-item>
          <el-form-item>
            <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <el-table
        :data="tableData"
        border
        style="width: 100%"
        v-loading="listLoading"
        ref="multipleTable"
        @current-change='currentChange'
        :highlight-current-row='true'
    >
      <el-table-column type='index' label="序号" width='80' align="center" :index='indexMethod'></el-table-column>
      <el-table-column prop="name" label="考生名称"  align="center" ></el-table-column>
      <el-table-column prop="province" label="省"  align="center" ></el-table-column>
      <el-table-column prop="departmentInfoName" label="组织名称"  align="center" ></el-table-column>
      <el-table-column prop="oproomProfessionDirName" label="职业等级方向"  align="center" ></el-table-column>
      <el-table-column prop="phoneNumber" label="手机号"  align="center" ></el-table-column>
<!--      <el-table-column prop="examModelTypeName" label="考试模式"  align="center" ></el-table-column>-->
      <el-table-column prop="createTime" label="考试时间"  align="center" ></el-table-column>
      <el-table-column prop="score" label="成绩"  align="center" ></el-table-column>
      <el-table-column prop="name" label="试卷详情"  align="center" >
	      <template #default="scope">
		      <el-button type="text" @click="preview(scope.row)">
			      {{'查看'}}
		      </el-button>
	      </template>
      </el-table-column>
    </el-table>
    <!--底部工具条-->
    <el-col :span="24" class="pageBar">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pages.pageIndex"
          :page-sizes="pages.pageArr"
          :page-size="pages.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pages.dataCount">
      </el-pagination>
    </el-col>
	  <el-dialog
			  title= "详情"
			  :visible.sync="detailDialog"
			  width="50%"
			  :close-on-click-modal='false'
			  :highlight-current-row='true'
	  >
	    <el-table
			    :data="detailDataList"
			    border
			    style="width: 100%"
			    v-loading="listLoading"
			    ref="multipleTable"
			    :highlight-current-row='true'
	    >
		    <el-table-column prop="content" label="题目"  align="center" height="40">
<!--			    <template #default="scope">-->
<!--				    鉴定点{{scope.row?scope.row.pointAliasName:'无'}}:{{scope.row?scope.row.paperExamQuestionResList[0].questionContent:undefined}}-->
<!--			    </template>-->
		    </el-table-column>
		    <el-table-column prop="score" label="扣分" width="120" align="center" height="40">
<!--			    <template #default="scope">-->
<!--				    {{scope.row?scope.row.paperExamQuestionResList[0].questionScore:undefined}}-->
<!--			    </template>-->
		    </el-table-column>
	    </el-table>
	  </el-dialog>
  </div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import {
	getAreaDictionaryList, getExamScoreList,getOrganizationTree,getExamDetailByExamId,
} from '../../api/api'

export default {
  components:{Toolbar},
  data() {
    return {
	    organizeNums:[],
      buttonList:[],
      tableData: [],
      areaArr: [],
      listLoading: false,
      //关于分页的obj
      pages: {
        pageSize: 20,//默认每页条数
        pageArr: [10, 20, 30, 40],//默认每页条数选择
        pageIndex: 1, //默认进入页
        dataCount: 20, //默认总页数
      },
      filters: {//查询条件
	      skillType:[],
        keyWord: undefined,
	      time:[],
      },
	    defaultProps: {//组织管理
		    children: "children",
		    hasChildren: "hasChildren",
		    label: "name",
		    value:'id' ,//可要可不要
		    multiple:true,
	    },
	    detailDialog:false,//详情

			detailDataList:[],//详情数据

    }
  },
  methods: {
		//查看
	  preview(val){
		  this.detailDataList = []
		  console.log(val,'detaile')
			let params = {
				paperExamId:val.id,
				studentAccountId:val.studentAccountId,
			}
		  getExamDetailByExamId(params).then(res=>{
			  console.log(res,'res')
			  if (res.data.success) {
				  const resData = res.data.response.paperExamIdentifyPointResList;
				  resData.forEach(item => {
					  const pointAliasName = item.pointAliasName;
					  const obj = item.paperExamQuestionResList.map(value => ({
						  content: '鉴定点' + pointAliasName + '：' + value.questionContent,
						  score: value.questionScore
					  }));
					  this.detailDataList.push(...obj);
				  });
			  }
		  })
			setTimeout(()=>{
				this.detailDialog = true ;
			},0)
	  },
	  //组织管理
	  getOrganizations() {
		  let _this = this
		  const userInfo = JSON.parse(window.localStorage.user)
		  let params = {
			  parentId: userInfo.departmentId
		  }
		  if(this.filters.selectc === ''){}else{
			  params.isEnable = this.filters.selectc
		  }
		  // if(this.filters.name){
		  //   params.name = this.filters.name
		  // }
		  this.listLoading = true
		  getOrganizationTree(params).then(res => {
			  let data = res.data
			  let resData = [data.response]
			  console.log(resData,'res.da')
			  resData.push()
			  if(data.success){
				  
				  this.selectVal = ''
				  this.listLoading = false
				  if(data.response.name == "根节点"){
					  let newObjData = JSON.stringify(data.response.children).replace(/\hasChildren/g,"HasChildren")
					  _this.organizeNums = JSON.parse(newObjData)
					  console.log(_this.organizeNums,'11111')
				  }else{
					  _this.organizeNums = resData
				  }
				  // _this.treeData = resData
			  }
		  })
	  },
	  handleChange(value) {
		  console.log(value,'组织管理选择')
	  },
    rowClass () {
      return 'padding:8px!important;'
    },
    callFunction(item) {
      this[item.func].apply(this, item);
    },
    // activeChange(val) {
    //   if(val === '2') {
    //     this.viewsApplicationWidth = "1200px"
    //   } else {
    //     this.viewsApplicationWidth = "60%"
    //   }
    // },
	  getListData(){
		  this.pages = {
			  pageIndex: 1,
			  pageSize: 20,
		  }
			this.getDataList();
	  },
	  
    // 列表查询接口
	  getDataList(){
	    console.log(this.filters,'11111')
		  const userInfo = JSON.parse(window.localStorage.user)
		  // console.log(userInfo,'info')
		  const ids = this.filters.skillType.map(item => {
			  return item[1]
		  })
		  let params = {
			  // cityCode:userInfo.cityCode,
			  // districtCode:userInfo.districtCode,
			  // provinceCode:userInfo.provinceCode,
        pageIndex: this.pages.pageIndex,
        pageSize: this.pages.pageSize,
	      keyWordkeyWord: this.filters.keyWord,//关键字
			  departmentId: ids.join(',')||userInfo.departmentId,//组织结构ID
	      endDate:this.filters.time[1],
	      startDate:this.filters.time[0],
      }
      this.listLoading = true
	    getExamScoreList(params).then(res => {
        let result = res.data
        if(result.success) {
          this.tableData = result.response.data
          this.pages.dataCount = result.response.dataCount
        }
        this.listLoading = false
      })
    },
    // 获取省
    getAreaList() {
      let params = {
        parentId: 1,
      }
      getAreaDictionaryList(params).then(res => {
        if (res.data.success) {
          this.areaArr = res.data.response.reverse();
        }
      })
    },
        // 点击table某一行
    currentChange(val) {
      this.needData = val
      // console.log(val,'val')
      // const myArray = [];
			//
			// // 使用push方法将整个对象添加到数组中
      // myArray.push(val);
      // this.needDataContact = myArray
    },
    // 分页
    handleCurrentChange(val) {
      this.pages.pageIndex = val;
      this.getDataList();
    },
    handleSizeChange(val){
      this.pages.pageSize = val;
      this.getDataList();
    },
    // // 时间格式化
    // formatCreateTime: function(row, column) {
    //   return !row || row == ""
    //       ? ""
    //       : util.formatDate.format(new Date(row), "yyyy-MM-dd hh:mm");
    // },
    // 表格数据分页条数累计
    indexMethod(index) {
      let currentPage = this.pages.pageIndex
      let pageSize = this.pages.pageSize
      return (index + 1) + (currentPage - 1) * pageSize
    },
  },
  watch: {

  },
  created(){
    this.utils = util
    let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
    this.buttonList = getButtonList(this.$route.path, routers);
	  this.getOrganizations()
    this.getDataList();
    // this.getAreaList()
  }
}
</script>

<style lang="stylus" scoped>
.projectName {
  text-align: center;
}
.projectSelect {
  margin-bottom: 15px;
}
.projectTitle {
  margin-bottom: 15px
}
.replyBtn {
  margin: 10px 0;
  color: #409eff;
  cursor: pointer;
  width: 30px;
}
.replyMessage {
  background: #eee;
  line-height: 30px;
  padding: 5px;
  border-radius: 2px;
  margin: 5px 15px;
}
.titleTimer {
  margin-left: 10px;
}
.green_t {
  color: green;
}
.red_t {
  color: red;
}
</style>
